import { configureStore } from "@reduxjs/toolkit";
import createUserSlice from "./redux/createUser";
import bosPaymentsSlice from "./redux/bos";

export const store = configureStore({
  reducer: {
    user: createUserSlice,
    bos: bosPaymentsSlice,
  },
});

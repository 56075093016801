import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { transferSchema } from "../schema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendPayout } from "../redux/bos";
import ClipLoader from "react-spinners/ClipLoader";
import { override } from "./PayIn";

const Payout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePayoutData, payoutLoader } = useSelector((state) => state.bos);
  const [externalRef, setExternalRef] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  let initialValues = {
    name: "",
    sender: "",
    accountNumber: "",
    bankIfsc: "",
    payeeListId: "",
    transferAmount: "",
    externalRef,
    latitude: "",
    longitude: "",
    remarks: "",
    alertEmail: "",
    purpose: "",
    transferMode: "IMPS",
    RegistrationID: "BOS-2167",
    CompanyCode: "CMP1045",
    Flag: "API",
  };

  const generateRandomString = (length = 8) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handlePayout = async (data, resetForm) => {
    try {
      const res = await dispatch(sendPayout(data));
      if (res.meta.requestStatus === "fulfilled") {
        console.log(res.payload.message);
        if (res.payload.message === "Transaction Successful") {
          toast.success(res.payload.message);
          resetForm();
        } else {
          toast.error(res.payload.message);
        }
      } else {
        resetForm();
        toast.error(res.payload.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: transferSchema,
    onSubmit: (values) => {
      const data = {
        payee: {
          name: values.name,
          accountNumber: values.accountNumber,
          bankIfsc: values.bankIfsc,
          payeeListId: values.payeeListId,
        },
        transferMode: values.transferMode,
        transferAmount: values.transferAmount,
        externalRef: generateRandomString(10),
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        remarks: values.remarks,
        alertEmail: values.alertEmail,
        purpose: values.purpose,
        RegistrationID: values.RegistrationID,
        CompanyCode: values.CompanyCode,
        Flag: values.Flag,
      };
      console.log("data", data);
      handlePayout(data, resetForm);
    },
  });

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (err) => {
          console.error("Error getting geolocation:", err);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
    setExternalRef(generateRandomString(10));
  }, []);

  const copyLink = (id) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success("OrderId copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy .");
      });
  };

  return (
    <>
      {payoutLoader ? (
        <div className="loading-spinner">
          <ClipLoader
            color={"#253992"}
            loading={payoutLoader}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="container">
          <div className="content-area card">
            <div className="card-innr card-innr-fix">
              <div className="card-head">
                <h6 className="card-title">Payout</h6>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="gaps-1x"></div>{" "}
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Payee Name
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.name && touched.name ? (
                      <p className="text-danger">{errors.name}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Account Number
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="accountNumber"
                        value={values.accountNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.accountNumber && touched.accountNumber ? (
                      <p className="text-danger">{errors.accountNumber}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Bank Ifsc
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="bankIfsc"
                        value={values.bankIfsc}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.bankIfsc && touched.bankIfsc ? (
                      <p className="text-danger">{errors.bankIfsc}</p>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Payee List Id
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="payeeListId"
                        value={values.payeeListId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.payeeListId && touched.payeeListId ? (
                      <p className="text-danger">{errors.payeeListId}</p>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Transfer Mode
                      </label>
                      <select
                        className="input-bordered"
                        type="text"
                        name="transferMode"
                        value={values.transferMode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Transfer Mode</option>
                        <option value={"UPI"}>UPI</option>
                        <option value={"IMPS"}>IMPS</option>
                        <option value={"NEFT"}>NEFT</option>
                        <option value={"RTGS"}>RTGS</option>
                      </select>
                    </div>
                    {errors.transferMode && touched.transferMode ? (
                      <p className="text-danger">{errors.transferMode}</p>
                    ) : null}
                  </div> */}
                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Transfer Amount
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="transferAmount"
                        value={values.transferAmount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="0.00"
                      />
                    </div>
                    {errors.transferAmount && touched.transferAmount ? (
                      <p className="text-danger">{errors.transferAmount}</p>
                    ) : null}
                  </div>

                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Remarks
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.remarks && touched.remarks ? (
                      <p className="text-danger">{errors.remarks}</p>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Alert Email
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="alertEmail"
                        value={values.alertEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.alertEmail && touched.alertEmail ? (
                      <p className="text-danger">{errors.alertEmail}</p>
                    ) : null}
                  </div>
                  {/* <div className="col-md-4">
                    <div className="input-item input-with-label">
                      <label className="input-item-label text-exlight">
                        Purpose
                      </label>
                      <input
                        className="input-bordered"
                        type="text"
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.purpose && touched.purpose ? (
                      <p className="text-danger">{errors.purpose}</p>
                    ) : null}
                  </div> */}
                </div>
                <div className="text-right mt-3">
                  <button className="btn btn-primary" type="submit">
                    Payout
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* ONLY SHOW IF THERE IS PAYOUTDATA */}

          {Object.keys(handlePayoutData).length > 0 && (
            <div class="card content-area">
              <div class="card-innr">
                <div class="card-head d-flex justify-content-between align-items-center">
                  <h4 class="card-title mb-0">Payout Details</h4>
                </div>

                <div className="mb-3">
                  <div
                    class="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div class="fake-class">
                      <span class="data-details-title">Account Number</span>
                      <span class="data-details-info">
                        {handlePayoutData?.data?.payee.account}
                      </span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Account Name</span>
                      <span class="data-details-info">
                        {handlePayoutData?.data?.payee.name}
                      </span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">
                        {handlePayoutData?.data?.TxnAmount}
                      </span>
                    </div>
                    <div
                      class="fake-class"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      {/* <span class="data-details-title badge badge-xl badge-success">
                      APPROVED
                    </span> */}
                    </div>
                  </div>
                  <ul
                    class="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div class="data-details-head">Ref ID</div>
                      <div class="data-details-des">
                        <strong>{handlePayoutData?.data?.RefID}</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Txn ReferenceId</div>
                      <div class="data-details-des">
                        <strong>
                          {handlePayoutData?.data?.txnReferenceId}
                        </strong>
                      </div>
                    </li>
                    {/* <li>
                    <div class="data-details-head">Created Date</div>
                    <div class="data-details-des">
                      <strong>
                        0xa87d264f935920005810653734156d3342d5c385
                      </strong>
                    </div>
                  </li> */}
                    <li>
                      <div class="data-details-head">Order ID</div>
                      <div className="data-details-des qr-main position-relative">
                        <strong>{handlePayoutData?.orderid}</strong>
                        <div
                          className="copy-btn"
                          onClick={() => copyLink(handlePayoutData?.orderid)}
                        >
                          Copy Order Id
                        </div>
                      </div>
                    </li>
                    {/* <li>
                    <div class="data-details-head">Registration ID</div>
                    <div class="data-details-des">
                      <strong>{item?.data?.RegistrationID}</strong>{" "}
                    </div>
                  </li>
                  <li>
                    <div class="data-details-head">Company Code</div>
                    <div class="data-details-des">
                      <strong>Tokens Purchase</strong>
                    </div>
                  </li> */}
                    <li>
                      <div class="data-details-head">BOS Signature</div>
                      <div class="data-details-des">
                        <strong>{handlePayoutData?.BOSSignature}</strong>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Payout;

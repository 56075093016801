import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  BOSDynamicQRCode,
  getAllDetailsByUserId,
  getAllPayoutDetails,
  getAllQrCodeDetails,
  OrderWiseTransactionStatus,
  payout,
} from "./api";

// ? Initial State
export const initialState = {
  loading: false,
  payoutLoader: false,
  error: null,
  qrData: "",
  allQrData: [],
  allPayoutData: [],
  allUserDetails: [],
  handlePayoutData: [],
  orderData: "",
};

export const generateDynamicQr = createAsyncThunk(
  "bos/createQr",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await BOSDynamicQRCode(credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);

export const getQrCodeDetails = createAsyncThunk(
  "bos/allQrcode",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await getAllQrCodeDetails(userId);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);

export const sendPayout = createAsyncThunk(
  "bos/payout",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await payout(credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);

export const handleOrderWiseTransactionStatus = createAsyncThunk(
  "bos/orderwisedata",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await OrderWiseTransactionStatus(credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);
export const getDetailsByUserId = createAsyncThunk(
  "bos/getAllDetailsByUserId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getAllDetailsByUserId(id);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);

const bosPaymentsSlice = createSlice({
  name: "bos",
  initialState,
  reducers: {
    bosLogout: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // * generateDynamicQr
      .addCase(generateDynamicQr.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateDynamicQr.fulfilled, (state, action) => {
        state.loading = false;
        state.qrData = action.payload?.data;
        state.error = null;
      })
      .addCase(generateDynamicQr.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // * getQrCodeDetails
      .addCase(getQrCodeDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQrCodeDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.allQrData = action.payload.data.qrData;
        state.allPayoutData = action.payload.data.payoutData;
        state.error = null;
      })
      .addCase(getQrCodeDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // * handlePayout
      .addCase(sendPayout.pending, (state) => {
        state.payoutLoader = true;
        state.error = null;
      })
      .addCase(sendPayout.fulfilled, (state, action) => {
        state.payoutLoader = false;
        state.handlePayoutData = action.payload.data;
        state.error = null;
      })
      .addCase(sendPayout.rejected, (state, action) => {
        state.payoutLoader = false;
        state.error = action.payload.message;
      })

      // * handleOrderWiseTransactionStatus
      .addCase(handleOrderWiseTransactionStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(handleOrderWiseTransactionStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.orderData = action.payload;
        state.error = null;
      })
      .addCase(handleOrderWiseTransactionStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // * getDetailsByUserId
      .addCase(getDetailsByUserId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDetailsByUserId.fulfilled, (state, action) => {
        state.loading = false;
        state.allUserDetails = action.payload.data;
        state.error = null;
      })
      .addCase(getDetailsByUserId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

// Export the actions and reducer
export const { bosLogout } = bosPaymentsSlice.actions;
export default bosPaymentsSlice.reducer;

import * as yup from "yup";

export const CreateUserSchema = yup.object({
  name: yup.string().min(4).max(50).required("Please Fill name"),
  email: yup.string().email().max(100).required("Please Fill email"),
  password: yup.string().min(6).max(50).required("Password required"),
  confirm_password: yup
    .string()
    .min(6)
    .max(50)
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  phone: yup
    .string()
    .matches(
      /^\+?[0-9]{0,10}$/, // Allows optional country code and ensures the phone number has between 10 and 15 digits
      "Only 10 digit allow"
    )
    .required("Phone number is required"),
});

export const transferSchema = yup.object({
  name: yup.string().required("Payee name is required"),
  accountNumber: yup
    .string()
    .matches(/^\d+$/, "Account number must contain only digits")
    .min(8, "Account number atleast have 8 digits")
    .max(18, "Account number atmost 18 digits")
    .required("Account number is required"),
  bankIfsc: yup
    .string()
    .matches(
      /^[A-Za-z0-9]{11}$/,
      "IFSC code must be 11 alphanumeric characters"
    )
    .required("Bank IFSC code is required"),
  payeeListId: yup.string().optional(),

  // transferMode: yup
  //   .string()
  //   .oneOf(["IMPS", "NEFT", "RTGS", "UPI"], "Invalid transfer mode")
  //   .required("Transfer mode is required"),
  transferAmount: yup
    .number()
    .positive("Transfer amount must be positive")
    .required("Transfer amount is required"),

  remarks: yup
    .string()
    .min(10, "Add 10 Character Atleast")
    .max(500, "Add 500 Character Atmost")
    .required("Remark is required"),
  alertEmail: yup
    .string()
    .email("Invalid email address")
    .required("Alert email is required"),
  purpose: yup.string().optional(),
  RegistrationID: yup.string().required("Registration ID is required"),
  CompanyCode: yup.string().required("Company code is required"),
  Flag: yup
    .string()
    .oneOf(["API"], "Invalid flag value")
    .required("Flag is required"),
});

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateDynamicQr } from "../redux/bos";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

export const override = {
  display: "block",
  margin: "0 auto",
};

const PayIn = () => {
  const dispatch = useDispatch();
  const {
    qrData: { details },
    loading,
  } = useSelector((state) => state.bos);

  const [amount, setAmount] = useState("");

  const handleData = async () => {
    const payload = {
      RegistrationId: "BOS-2167",
      amount,
    };

    if (!amount) {
      return toast.error("Please Enter The Amount");
    }

    const res = await dispatch(generateDynamicQr(payload));

    if (res.meta.requestStatus === "fulfilled") {
      setAmount("");
      toast.success(res.payload.message);
    } else {
      toast.error(res.payload.message);
    }
  };

  const copyLink = () => {
    if (details && details.intent_url) {
      navigator.clipboard
        .writeText(details.intent_url)
        .then(() => {
          toast.success("QR code copied to clipboard!");
        })
        .catch(() => {
          toast.error("Failed to copy QR code.");
        });
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="loading-spinner">
          <ClipLoader
            color={"#253992"}
            loading={loading}
            cssOverride={override}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <div className="content-area card">
            <div className="card-innr card-innr-fix">
              <div className="card-head">
                <h6 className="card-title">PayIn</h6>
              </div>

              <div className="row gutter-2 align-items-end">
                <div className="col">
                  <div className="input-item input-with-label p-0">
                    <label className="input-item-label text-exlight">
                      Amount
                    </label>
                    <input
                      className="input-bordered"
                      type="number"
                      name="amount"
                      value={amount}
                      placeholder="Please Enter the Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div className="pl-0 col-auto text-right mt-3">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ONLY SHOW IF QRDATA HAS VALUE */}
          {details && (
            <div className="card content-area">
              <div className="card-innr">
                <div className="card-head d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">PayIn Details</h4>
                </div>
                <div className="mb-3">
                  <div
                    className="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div className="fake-class">
                      <span className="data-details-title">
                        Registration ID
                      </span>
                      <span className="data-details-info">BOS-2167</span>
                    </div>
                    <div
                      className="fake-class"
                      style={{
                        maxWidth: "150px",
                      }}
                    ></div>
                  </div>
                  <ul
                    className="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div className="data-details-head">UPI Ref ID</div>
                      <div className="data-details-des">
                        <strong>{details?.UPIRefID}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">VPA</div>
                      <div className="data-details-des">
                        <strong>{details?.payeeVPA}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">Merchant Id</div>
                      <div className="data-details-des">
                        <strong>{details?.merchantId}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">QR Code</div>
                      <div className="data-details-des qr-main position-relative">
                        <strong>{details?.intent_url}</strong>
                        <div className="copy-btn" onClick={copyLink}>
                          Copy QR Code
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PayIn;

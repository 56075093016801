import axios from "axios";
import { jwtDecode } from "jwt-decode";

// const baseUrl = "http://localhost:5001/api/v1";
const baseUrl = "https://vgwallet.in/api/v1";

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    const expiry = decodedToken.exp * 6 * 60 * 60 * 1000;

    if (token && expiry) {
      const now = new Date().getTime();
      if (now >= expiry) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiry");
        window.location.href = "/login";
        return Promise.reject(new Error("Token expired"));
      } else {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    } else {
      window.location.href = "/login";
      return Promise.reject(new Error("No token found"));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpiry");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor to include the token in headers
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const authToken = localStorage.getItem("token");
//     if (authToken) {
//       config.headers["Authorization"] = `Bearer ${authToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

export const userLogin = (credentials) => {
  return axios.post(`${baseUrl}/login`, credentials);
};
export const createUser = (data) => {
  return axiosInstance.post(`/create/user`, data);
};
export const getAllUser = () => {
  return axiosInstance.get(`/users`);
};
export const getAllQrCodeDetails = (id) => {
  const url = id
    ? `${baseUrl}/all/qr-code/payout/${id}`
    : `${baseUrl}/all/qr-code/payout`;
  return axiosInstance.get(url);
};

export const payout = (data) => {
  return axiosInstance.post(`/send/payout`, data);
};
export const BOSDynamicQRCode = (data) => {
  return axiosInstance.post(`/qr-code`, data);
};
export const OrderWiseTransactionStatus = (orderId) => {
  return axiosInstance.post(`/orders/${orderId}`);
};
export const getAllDetailsByUserId = (userId) => {
  return axiosInstance.get(`/details/${userId}`);
};

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const OrderByUser = () => {
  return (
    <div className="udetails">
      <div className="container">
        <Tabs>
          <TabList>
            <Tab>
              <button className="btn btn-outline btn-dark w-100">
                PayIn (200)
              </button>
            </Tab>
            <Tab>
              <button className="btn btn-outline btn-dark t w-100">
                PayOut (100)
              </button>
            </Tab>
          </TabList>

          <TabPanel>
            <div class="card content-area">
              <div class="card-innr">
                <div class="card-head d-flex justify-content-between align-items-center">
                  <h4 class="card-title mb-0">
                    <b
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      PayIn
                    </b>
                  </h4>
                  <div className="row g-2">
                    <div class="input-item input-with-label d-inline-block col-auto">
                      <input
                        class="input-bordered"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                    <div class="input-item input-with-label d-inline-block col-auto">
                      <div class="select-wrapper">
                        <select
                          class="input-bordered"
                          style={{
                            minWidth: "160px",
                          }}
                        >
                          <option value="option-one">Select Status</option>
                          <option value="option-two">Pending</option>
                          <option value="option-three">Collected</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Single */}
                <div className="mb-3">
                  <div
                    class="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div class="fake-class">
                      <span class="data-details-title">Registration ID</span>
                      <span class="data-details-info">51515552992</span>
                    </div>

                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">100</span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">100</span>
                    </div>
                    <div
                      class="fake-class"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      <span class="data-details-title badge badge-xl badge-success">
                        APPROVED
                      </span>
                    </div>
                  </div>
                  <ul
                    class="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div class="data-details-head">UPI Ref ID</div>
                      <div class="data-details-des">
                        <strong>Purchase</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Txn Referance</div>
                      <div class="data-details-des">
                        <strong>Ethereum</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Created Date</div>
                      <div class="data-details-des">
                        <strong>
                          0xa87d264f935920005810653734156d3342d5c385
                        </strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">QR Code</div>
                      <div class="data-details-des  qr-main position-relative">
                        <strong>
                          0xa87d264f935920005810653734156d3342d5c385
                        </strong>
                        <div className="copy-btn">Copy Code</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="mb-3">
                  <div
                    class="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div class="fake-class">
                      <span class="data-details-title">Registration ID</span>
                      <span class="data-details-info">51515552992</span>
                    </div>

                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">100</span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">100</span>
                    </div>
                    <div
                      class="fake-class"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      <span class="data-details-title badge badge-xl badge-success">
                        APPROVED
                      </span>
                    </div>
                  </div>
                  <ul
                    class="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div class="data-details-head">UPI Ref ID</div>
                      <div class="data-details-des">
                        <strong>Purchase</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Txn Referance</div>
                      <div class="data-details-des">
                        <strong>Ethereum</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Created Date</div>
                      <div class="data-details-des">
                        <strong>
                          0xa87d264f935920005810653734156d3342d5c385
                        </strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">QR Code</div>
                      <div class="data-details-des  qr-main position-relative">
                        <strong>
                          0xa87d264f935920005810653734156d3342d5c385
                        </strong>
                        <div className="copy-btn">Copy Code</div>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Single */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div class="card content-area">
              <div class="card-innr">
                <div class="card-head d-flex justify-content-between align-items-center">
                  <h4 class="card-title mb-0">
                    <b
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      PayOut
                    </b>
                  </h4>
                  <div className="row g-2">
                    <div class="input-item input-with-label d-inline-block col-auto">
                      <input
                        class="input-bordered"
                        type="text"
                        placeholder="Search"
                      />
                    </div>
                    <div class="input-item input-with-label d-inline-block col-auto">
                      <div class="select-wrapper">
                        <select
                          class="input-bordered"
                          style={{
                            minWidth: "160px",
                          }}
                        >
                          <option value="option-one">Select Status</option>
                          <option value="option-two">Pending</option>
                          <option value="option-three">Collected</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Single */}
                <div className="mb-3">
                  <div
                    class="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div class="fake-class">
                      <span class="data-details-title">Account Number</span>
                      <span class="data-details-info">51515552992</span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Account Name</span>
                      <span class="data-details-info">Admin</span>
                    </div>
                    <div class="fake-class">
                      <span class="data-details-title">Txn Amount</span>
                      <span class="data-details-info">100</span>
                    </div>
                    <div
                      class="fake-class"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      <span class="data-details-title badge badge-xl badge-success">
                        APPROVED
                      </span>
                    </div>
                  </div>
                  <ul
                    class="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div class="data-details-head">Ref ID</div>
                      <div class="data-details-des">
                        <strong>Purchase</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Txn ReferenceId</div>
                      <div class="data-details-des">
                        <strong>Ethereum</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Created Date</div>
                      <div class="data-details-des">
                        <strong>
                          0xa87d264f935920005810653734156d3342d5c385
                        </strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Order ID</div>
                      <div class="data-details-des">
                        <strong>
                          Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93
                        </strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Registration ID</div>
                      <div class="data-details-des">
                        <strong>
                          0xa058106537340385156d3342d5c87d264f935920
                        </strong>{" "}
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">Company Code</div>
                      <div class="data-details-des">
                        <strong>Tokens Purchase</strong>
                      </div>
                    </li>
                    <li>
                      <div class="data-details-head">BOS Signature</div>
                      <div class="data-details-des">
                        <strong>
                          Tx156d3342d5c87d264f9359200xa058106537340385c87d264f93
                        </strong>
                      </div>
                    </li>
                  </ul>
                </div>
                {/* Single */}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default OrderByUser;

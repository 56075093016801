import { useFormik } from "formik";
import { CreateUserSchema } from "../schema";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createUsers } from "../redux/createUser";
import UserList from "../components/userList";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  password: "",
  confirm_password: "",
};

const CreateUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateUser = async (data) => {
    try {
      const res = await dispatch(createUsers(data));
      console.log(res);
      if (res.meta.requestStatus === "fulfilled") {
        toast.success(res.payload.message);
      } else {
        toast.error(res.payload.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: CreateUserSchema,
      onSubmit: (values) => {
        const data = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          password: values.password,
          confirm_password: values.confirm_password,
        };
        handleCreateUser(data);
      },
    });
  return (
    <>
      <div className="container">
        <div className="content-area card">
          <div className="card-innr card-innr-fix">
            <div className="card-head">
              <h6 className="card-title">Create User Role</h6>
            </div>
            <div className="gaps-1x"></div>{" "}
            <form action="#" onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-4">
                  <div className="input-item input-with-label">
                    <label className="input-item-label text-exlight">
                      Name
                    </label>
                    <input
                      className="input-bordered"
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.name && touched.name ? (
                      <p className="text-danger">{errors.name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-item input-with-label">
                    <label className="input-item-label text-exlight">
                      Email
                    </label>
                    <input
                      className="input-bordered"
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className="text-danger">{errors.email}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-item input-with-label">
                    <label className="input-item-label text-exlight">
                      Phone No
                    </label>
                    <input
                      className="input-bordered"
                      type="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phone && touched.phone ? (
                      <p className="text-danger">{errors.phone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-with-label">
                    <label className="input-item-label text-exlight">
                      Password
                    </label>
                    <input
                      className="input-bordered"
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password ? (
                      <p className="text-danger">{errors.password}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-item input-with-label">
                    <label className="input-item-label text-exlight">
                      Confirm Password
                    </label>
                    <input
                      className="input-bordered"
                      type="password"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.confirm_password && touched.confirm_password ? (
                      <p className="text-danger">{errors.confirm_password}</p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="text-right">
                <button className="btn btn-primary" type="submit">
                  Create User
                </button>
              </div>
            </form>
          </div>
        </div>
        <UserList />
      </div>
    </>
  );
};

export default CreateUsers;

import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./utils/Layout"; // Layout for admin routes
import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import CreateUsers from "./pages/CreateUsers";
import Payout from "./pages/Payout";
import PayIn from "./pages/PayIn";
import TransactionStatus from "./pages/TransactionStatus";
import UserDetail from "./pages/UserDetail";
import OrderByUser from "./pages/OrderByUser";
import AllOrder from "./pages/AllOrder";
import ProtectedRoute from "./utils/ProtectedRoute";

const App = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/user/details"
        element={
          <ProtectedRoute>
            <UserDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Dashboard />} />
        <Route path="payout" element={<Payout />} />
        <Route path="payin" element={<PayIn />} />
        <Route path="transactionStatus" element={<TransactionStatus />} />
        <Route path="orderByUser" element={<OrderByUser />} />
        <Route path="allOrder" element={<AllOrder />} />
        {}
        <Route path="users" element={<CreateUsers />} />
      </Route>
    </Routes>
  );
};

export default App;

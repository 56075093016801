import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../redux/createUser";

const UserList = () => {
  const dispatch = useDispatch();
  const { userDetails, data } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [data]);

  const filteredUserDetails = userDetails.filter((item) => item.id !== 1);
  return (
    <div className=" main-content">
      <div className="card content-area">
        <div className="card-innr">
          <div className="card-head">
            <h4 className="card-title">User List</h4>
          </div>
          <div
            id="DataTables_Table_0_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="table-wrap">
              <table className="data-table dt-init user-list dataTable no-footer">
                <thead>
                  <tr className="data-item data-head" role="row">
                    <th
                      className="data-col dt-user sorting_disabled"
                      rowSpan="1"
                      colSpan="1"
                    >
                      User
                    </th>
                    <th
                      className="data-col  sorting_disabled"
                      rowSpan="1"
                      colSpan="1"
                    >
                      Phone No
                    </th>
                    <th
                      className="data-col  sorting_disabled"
                      rowSpan="1"
                      colSpan="1"
                    >
                      Email
                    </th>
                    <th
                      className="data-col dt-status sorting_disabled"
                      rowSpan="1"
                      colSpan="1"
                    >
                      <div className="dt-status-text">Status</div>
                    </th>
                    <th
                      className="data-col sorting_disabled"
                      rowSpan="1"
                      colSpan="1"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  {filteredUserDetails.map((item) => (
                    <tr className="data-item even" role="row" key={item.id}>
                      <td className="data-col dt-user">
                        <div className="user-block">
                          <div className="user-photo">
                            {item.name.slice(0, 2)}
                          </div>
                          <div className="user-info">
                            <span className="lead user-name">{item.name}</span>
                            {/* <span className="sub user-id">UD102005</span> */}
                          </div>
                        </div>
                      </td>
                      <td className="data-col ">
                        <span className="sub sub-s2 sub-email">
                          {item.phone}
                        </span>
                      </td>
                      <td className="data-col ">
                        <span className="sub sub-s2 sub-email">
                          {item.email}
                        </span>
                      </td>
                      <td className="data-col dt-status">
                        <span className="dt-status-md badge badge-outline badge-success badge-md">
                          Active
                        </span>
                        <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                          A
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;

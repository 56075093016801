import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQrCodeDetails } from "../redux/bos";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { allQrData, allPayoutData } = useSelector((state) => state.bos);

  useEffect(() => {
    dispatch(getQrCodeDetails());
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="token-statistics card card-token height-auto">
              <div className="card-innr">
                <div className="token-balance token-balance-with-icon m-0">
                  <div className="token-balance-text">
                    <h6 className="card-sub-title">Total PayIn Operations</h6>
                    <span className="lead">{allQrData.length}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="token-statistics card  height-auto">
              <div className="card-innr">
                <div className="token-balance token-balance-with-icon m-0">
                  <div className="token-balance-text">
                    <h6 className="card-sub-title">Total PayIn</h6>
                    <span className="lead text-dark">2500</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-3">
            <div className="token-statistics card card-token height-auto">
              <div className="card-innr">
                <div className="token-balance token-balance-with-icon m-0">
                  <div className="token-balance-text">
                    <h6 className="card-sub-title">Total PayOut Operations</h6>
                    <span className="lead">{allPayoutData.length}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="token-statistics card  height-auto">
              <div className="card-innr">
                <div className="token-balance token-balance-with-icon m-0">
                  <div className="token-balance-text">
                    <h6 className="card-sub-title">Total PayOut</h6>
                    <span className="lead text-dark">2500</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

const UserDetail = () => {
  return <div>sdfsdf</div>;
};

export default UserDetail;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getQrCodeDetails } from "../redux/bos";
import { toast } from "react-toastify";
import { getAllUsers } from "../redux/createUser";
import { formatDate } from "../utils/FormatDate";

const AllOrder = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const { allQrData, allPayoutData } = useSelector((state) => state.bos);
  const { userDetails } = useSelector((state) => state.user);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredQrData, setFilteredQrData] = useState([]);
  const [filteredPayoutData, setFilteredPayoutData] = useState([]);

  useEffect(() => {
    dispatch(getQrCodeDetails());
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    setFilteredQrData(
      allQrData.filter(
        (item) =>
          item.vpa?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.merchantId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.UPIRefID?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredPayoutData(
      allPayoutData.filter(
        (item) =>
          item.accountNumber
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.ref_id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.orderid?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, allQrData, allPayoutData]);

  const getDataByUserId = async (id, text) => {
    const res = await dispatch(getQrCodeDetails(id));
    if (res.meta.requestStatus === "fulfilled") {
      toast.success(
        text === "qr"
          ? "Qr Data Get Successfully!"
          : "Payout Data Get Successfully!"
      );
    } else {
      toast.error(res.payload.message);
    }
  };

  useEffect(() => {
    dispatch(getQrCodeDetails());
    dispatch(getAllUsers());
  }, []);

  const copyLink = (id, text) => {
    navigator.clipboard
      .writeText(id)
      .then(() => {
        toast.success(
          text === "qr"
            ? "Qr Code copied to clipboard!"
            : "Order Id copied to clipboard!"
        );
      })
      .catch(() => {
        toast.error("Failed to copy QR code.");
      });
  };

  const userIdToNameMap = userDetails.reduce((acc, user) => {
    acc[user.id] = user.name;
    return acc;
  }, {});

  return (
    <div className="udetails">
      <div className="container">
        <Tabs>
          <TabList>
            <Tab>
              <button className="btn btn-outline btn-dark w-100">
                PayIn ({allQrData.length})
              </button>
            </Tab>
            <Tab>
              <button className="btn btn-outline btn-dark t w-100">
                PayOut ({allPayoutData.length})
              </button>
            </Tab>
          </TabList>

          {/* PAYIN */}
          <TabPanel>
            <div className="card content-area">
              <div className="card-innr">
                <div className="card-head d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">
                    <b
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      PayIn
                    </b>
                  </h4>
                  <div className="row g-2">
                    <div className="input-item input-with-label d-inline-block col-auto">
                      <input
                        className="input-bordered"
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    {role === "admin" && (
                      <div className="input-item input-with-label d-inline-block col-auto">
                        <div className="select-wrapper">
                          <select
                            className="input-bordered"
                            style={{
                              minWidth: "160px",
                            }}
                            onChange={(e) =>
                              getDataByUserId(e.target.value, "qr")
                            }
                          >
                            <option value={""}>All</option>
                            {userDetails.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Single */}
                {filteredQrData.length > 0
                  ? filteredQrData.map((item) => (
                      <div className="mb-3">
                        <div
                          className="data-details d-md-flex"
                          style={{
                            borderBottomRightRadius: "0",
                            borderBottomLeftRadius: "0",
                          }}
                        >
                          <div className="fake-className">
                            <span className="data-details-title">
                              Registration ID
                            </span>
                            <span className="data-details-info">BOS-2167</span>
                          </div>

                          <div className="fake-className">
                            <span className="data-details-title">
                              Txn Amount
                            </span>
                            <span className="data-details-info">
                              {item?.amount}
                            </span>
                          </div>
                          <div
                            className="fake-className"
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            {/* <span className="data-details-title badge badge-xl badge-success">
                              APPROVED
                            </span> */}
                            <span
                              className="data-details-title"
                              style={{
                                color: "#495463",
                                background: "#e6effb",
                                borderColor: "#e6effb",
                                padding: "12px",
                                maxWidth: "178px",
                                borderRadius: "6px",
                                textAlign: "center",
                              }}
                            >
                              {userIdToNameMap[item?.userId] || "N/A"}
                            </span>
                          </div>
                        </div>
                        <ul
                          className="data-details-list"
                          style={{
                            borderTop: "0",
                            borderTopRightRadius: "0",
                            borderTopLeftRadius: "0",
                          }}
                        >
                          <li>
                            <div className="data-details-head">UPI Ref ID</div>
                            <div className="data-details-des">
                              <strong>{item?.UPIRefID}</strong>
                            </div>
                          </li>
                          <li>
                            <div className="data-details-head">VPA</div>
                            <div className="data-details-des">
                              <strong>{item?.vpa}</strong>
                            </div>
                          </li>
                          <li>
                            <div className="data-details-head">Merchant Id</div>
                            <div className="data-details-des">
                              <strong>{item?.merchantId}</strong>
                            </div>
                          </li>
                          <li>
                            <div className="data-details-head">Created At</div>
                            <div className="data-details-des">
                              <strong>{formatDate(item?.created_at)}</strong>
                            </div>
                          </li>

                          <li>
                            <div className="data-details-head">Qr Code</div>
                            <div className="data-details-des qr-main position-relative">
                              <strong>{item?.b64}</strong>
                              <div
                                className="copy-btn"
                                onClick={() => copyLink(item?.b64, "qr")}
                              >
                                Copy QR Code
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))
                  : "No Data Found"}

                {/* Single */}
              </div>
            </div>
          </TabPanel>

          {/* PAYOUT */}
          <TabPanel>
            <div className="card content-area">
              <div className="card-innr">
                <div className="card-head d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">
                    <b
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      PayOut
                    </b>
                  </h4>
                  <div className="row g-2">
                    <div className="input-item input-with-label d-inline-block col-auto">
                      <input
                        className="input-bordered"
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value, "")}
                      />
                    </div>

                    {role === "admin" && (
                      <div className="input-item input-with-label d-inline-block col-auto">
                        <div className="select-wrapper">
                          <select
                            className="input-bordered"
                            style={{
                              minWidth: "160px",
                            }}
                            onChange={(e) => getDataByUserId(e.target.value)}
                          >
                            <option value={""}>All</option>
                            {userDetails.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Single */}
                {filteredPayoutData.length > 0
                  ? filteredPayoutData.map((item) => (
                      <div className="mb-3">
                        <div
                          className="data-details d-md-flex"
                          style={{
                            borderBottomRightRadius: "0",
                            borderBottomLeftRadius: "0",
                          }}
                        >
                          <div className="fake-className">
                            <span className="data-details-title">
                              Account Number
                            </span>
                            <span className="data-details-info">
                              {item?.accountNumber}
                            </span>
                          </div>
                          <div className="fake-className">
                            <span className="data-details-title">
                              Account Name
                            </span>
                            <span className="data-details-info">
                              {item?.name.toUpperCase()}
                            </span>
                          </div>
                          <div className="fake-className">
                            <span className="data-details-title">
                              Txn Amount
                            </span>
                            <span className="data-details-info">
                              {item?.transferAmount}
                            </span>
                          </div>
                          <div className="fake-className text-right">
                            <span
                              className="data-details-title"
                              style={{
                                color: "#495463",
                                background: "#e6effb",
                                borderColor: "#e6effb",
                                padding: "12px",
                                maxWidth: "178px",
                                borderRadius: "6px",
                                textAlign: "center",
                                marginLeft: "auto",
                              }}
                            >
                              {userIdToNameMap[item?.userId] || "N/A"}
                            </span>
                            <span className="data-details-info"></span>
                          </div>
                        </div>
                        <ul
                          className="data-details-list"
                          style={{
                            borderTop: "0",
                            borderTopRightRadius: "0",
                            borderTopLeftRadius: "0",
                          }}
                        >
                          <li>
                            <div className="data-details-head">Ref ID</div>
                            <div className="data-details-des">
                              <strong>{item?.ref_id || "--"} </strong>
                            </div>
                          </li>
                          <li>
                            <div className="data-details-head">Bank Isfc</div>
                            <div className="data-details-des">
                              <strong>{item?.bankIfsc}</strong>
                            </div>
                          </li>

                          <li>
                            <div className="data-details-head">
                              Txn ReferenceId
                            </div>
                            <div className="data-details-des">
                              <strong>{item?.txn_reference_id || "--"}</strong>
                            </div>
                          </li>

                          <li>
                            <div className="data-details-head">Order ID</div>
                            <div className="data-details-des qr-main position-relative">
                              <strong>{item?.orderid || "--"}</strong>
                              <div
                                className="copy-btn"
                                onClick={() => copyLink(item?.orderid, "")}
                              >
                                Copy Order Id
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="data-details-head">Send At</div>
                            <div className="data-details-des">
                              <strong>{formatDate(item?.createdAt)}</strong>
                            </div>
                          </li>
                          <li>
                            <div className="data-details-head">Status</div>
                            <div className="data-details-des position-relative">
                              <span
                                className={`data-details-title badge badge-xl ${
                                  item?.message === "Transaction Successful"
                                    ? "badge-success"
                                    : " badge-danger"
                                }`}
                              >
                                {item?.message}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))
                  : "No Data Found"}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default AllOrder;

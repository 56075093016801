import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { userLogout } from "../redux/createUser";
import { bosLogout } from "../redux/bos";

const Header = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const name = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(userLogout());
    dispatch(bosLogout());
    navigate("/login");
  };

  const handleClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div className="topbar-wrap">
        <div className="topbar is-sticky">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  <a
                    onClick={handleClick}
                    className={isActive ? "toggle-nav active" : "toggle-nav "}
                    href="#"
                  >
                    <div className="toggle-icon">
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                    </div>
                  </a>
                </li>
                {/* .topbar-nav-item */}
              </ul>
              {/* .topbar-nav */}
              <Link to={"/"} className="topbar-logo ">
                <h3 className="text-white m-0">
                  <b>VGWallet</b>
                </h3>
              </Link>
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  <span className="user-welcome d-none d-lg-inline-block">
                    Welcome! {name}
                  </span>
                  <button
                    className="toggle-tigger user-thumb"
                    style={{
                      border: "none",
                    }}
                    onClick={toggleMenu}
                  >
                    <em className="ti ti-user"></em>
                  </button>
                  {isOpen && (
                    <div
                      className="toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown d-block"
                      style={{
                        minWidth: "190px",
                      }}
                    >
                      <div className="user-status">
                        <h6 className="user-status-title">Logged in as</h6>
                        <div className="user-status-balance">
                          {name}
                          <small></small>
                        </div>
                      </div>
                      {/* <ul className="user-links">
                        <li>
                          <Link to={"profile"}>
                            <i className="ti ti-id-badge"></i>My Profile
                          </Link>
                        </li>
                        <li>
                          <Link to={"#"}>
                            <i className="ti ti-infinite"></i>My Order
                          </Link>
                        </li>
                      </ul> */}
                      <ul
                        className="user-links bg-light"
                        onClick={handleLogout}
                      >
                        <li>
                          <Link to={""}>
                            <i className="ti ti-power-off"></i>Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                {/* .topbar-nav-item */}
              </ul>
              {/* .topbar-nav */}
            </div>
          </div>
          {/* .container */}
        </div>
        {/* .topbar */}
        <div className={isActive ? "navbar navbar-mobile active" : "navbar"}>
          <div className="container">
            <div className="navbar-innr">
              <ul className="navbar-menu">
                <li className="active">
                  <NavLink exact to={"/"} activeClassName="active">
                    <em className="ikon ikon-dashboard"></em> Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/payin"}>
                    <em className="ikon ikon-coins"></em> PayIn
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/payout"}>
                    <em className="ikon ikon-distribution"></em> PayOut
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/transactionStatus"}>
                    <em className="ikon ikon-transactions"></em> Transaction
                    Status
                  </NavLink>
                </li>
                {role === "admin" && (
                  <li>
                    <NavLink to={"/users"}>
                      <em className="ikon ikon-user"></em> Users
                    </NavLink>
                  </li>
                )}
              </ul>
              <ul className="navbar-btns">
                <li>
                  <Link
                    to={"/AllOrder"}
                    className="btn btn-sm btn-outline btn-light"
                  >
                    <em className="text-primary ti ti-files"></em>
                    <span>ALL ORDER</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* .navbar-innr */}
          </div>
          {/* .container */}
        </div>
        {/* .navbar */}
      </div>
      {/* .topbar-wrap */}
      {/* sdf */}
    </>
  );
};

export default Header;

import React, { useState } from "react";
import { handleOrderWiseTransactionStatus } from "../redux/bos";
import { useDispatch, useSelector } from "react-redux";

const TransactionStatus = () => {
  const dispatch = useDispatch();
  const {
    orderData: { data },
  } = useSelector((state) => state.bos);
  const [orderId, setOrderId] = useState("");
  const handleOrderId = () => {
    const res = dispatch(handleOrderWiseTransactionStatus(orderId));
    setOrderId("");
  };

  return (
    <>
      <div className="container">
        <div className="content-area card">
          <div className="card-innr card-innr-fix">
            <div className="card-head">
              <h6 className="card-title">Transaction Status</h6>
            </div>

            <div className="row align-items-end">
              <div className="col">
                <div className="input-item input-with-label p-0">
                  <label className="input-item-label text-exlight">
                    OrderID
                  </label>
                  <input
                    className="input-bordered"
                    type="text"
                    name="orderId"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-auto pl-0">
                <div className="text-right mt-0">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleOrderId}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {data ? (
          data.length > 0 ? (
            <div className="card content-area">
              <div className="card-innr">
                <div className="card-head d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">Transaction Status</h4>
                </div>
                <div className="">
                  <div
                    className="data-details d-md-flex"
                    style={{
                      borderBottomRightRadius: "0",
                      borderBottomLeftRadius: "0",
                    }}
                  >
                    <div className="fake-class">
                      <span className="data-details-title">Order ID</span>
                      <span className="data-details-info">
                        {data[0]?.orderid}
                      </span>
                    </div>

                    <div className="fake-class">
                      <span className="data-details-title">Txn Amount</span>
                      <span className="data-details-info">
                        {data[0]?.txnValue}
                      </span>
                    </div>
                    <div className="fake-class">
                      <span
                        className={`data-details-title badge badge-xl ${
                          data[0]?.status === "Transaction successful"
                            ? "badge-success"
                            : "badge-danger"
                        }  `}
                      >
                        {data[0]?.status}
                      </span>
                    </div>
                  </div>
                  <ul
                    className="data-details-list"
                    style={{
                      borderTop: "0",
                      borderTopRightRadius: "0",
                      borderTopLeftRadius: "0",
                    }}
                  >
                    <li>
                      <div className="data-details-head">Referance ID</div>
                      <div className="data-details-des">
                        <strong>{data[0]?.txnReferenceId}</strong>
                      </div>
                    </li>

                    <li>
                      <div className="data-details-head">Tran Date</div>
                      <div className="data-details-des">
                        <strong>{data[0]?.timestamps}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">External Ref</div>
                      <div className="data-details-des">
                        <strong>{data[0]?.externalRef}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">RemitterName</div>
                      <div className="data-details-des">
                        <strong>{data[0]?.Payeename}</strong>
                      </div>
                    </li>
                    <li>
                      <div className="data-details-head">RemitterAccountNo</div>
                      <div className="data-details-des">
                        <strong>{data[0]?.Payeeaccount}</strong>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div className="card content-area">
              <div className="card-innr">
                <div className="card-head d-flex justify-content-between align-items-center">
                  <h4 className="card-title mb-0">
                    No Data Found With This OrderId
                  </h4>
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
    </>
  );
};

export default TransactionStatus;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createUser, getAllUser, userLogin } from "./api";
import bosPaymentsSlice from "./bos";

// ? Initial State
export const initialState = {
  loading: false,
  error: null,
  userDetails: [],
  user: "",
  token: "",
  data: "",
};

export const createUsers = createAsyncThunk(
  "user/create",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await createUser(credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);
export const loginUser = createAsyncThunk(
  "user/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await userLogin(credentials);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue("An unknown error occurred.");
      }
    }
  }
);

export const getAllUsers = createAsyncThunk("users", async () => {
  const response = await getAllUser();
  return response.data;
});

const createUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogout: (state) => {
      state.loading = false;
      state.error = null;
      state.userDetails = [];
      state.user = "";
      state.token = "";
      state.data = "";
      // Clear localStorage or any other persistent storage if needed
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("role");
    },
  },
  extraReducers: (builder) => {
    builder

      // * LOGIN USERS
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.data;
        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("user", action.payload.data.user.name);
        localStorage.setItem("role", action.payload.data.user.role);
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // * CREATE USERS
      .addCase(createUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(createUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // * GET ALL USERS
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload.data;
        state.error = null;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

// Export the actions and reducer
export const { userLogout } = createUserSlice.actions;
export default createUserSlice.reducer;

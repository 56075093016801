import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/createUser";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    const res = await dispatch(loginUser(data));
    if (res.meta.requestStatus === "fulfilled") {
      toast.success(res.payload.message);
      navigate("/");
    } else {
      toast.error(res.payload.message);
    }
  };

  return (
    <>
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <div className="page-ath-header">
            <Link to={"/Login"} className="page-ath-logo">
              LOGO
            </Link>
          </div>
          <div className="page-ath-form">
            <h2 className="page-ath-heading">
              Log in <small>with your VG Wallet Account</small>
            </h2>
            <form action="#" onSubmit={handleSubmit}>
              <div className="input-item">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="input-bordered"
                  name="email"
                />
              </div>
              <div className="input-item">
                <input
                  type="password"
                  placeholder="Password"
                  className="input-bordered"
                  name="password"
                />
              </div>

              <button className="btn btn-primary btn-block" type="submit">
                Log In
              </button>
            </form>

            <div className="gaps-2x"></div>
            <div className="gaps-2x"></div>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>&copy; 2024 VGWallet.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5">
              <img src="assets/images/ath-gfx-new.png" alt="image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
